import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RetailerPromoSchedule} from "@looma/shared/models/retailer_promo_schedule";
import {RetailerPromoProgram} from "@looma/shared/models/retailer_promo_program";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {switchMap, takeUntil} from "rxjs/operators";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {ColorSpace, Utils} from "@looma/shared/utils";
import gql from "graphql-tag";
import {ApiDataService} from "../../../../services/api-data.service";
import {LayoutService} from "../../../../services/layout.service";
import {BrandCampaignEditDialogComponent} from "../brand-campaign-edit-dialog/brand-campaign-edit-dialog.component";
import {ModelListDataSource} from "../../../../layout/components/looma-grid/grid-data-source";
import {
    BrandPromoCampaign,
    BrandPromoCampaignSlotAssignment,
    BrandPromoCampaignSlotAssignmentInput
} from "@looma/shared/models/brand_promo_campaign";
import {RetailerPromoPeriod} from "@looma/shared/models/retailer_promo_periods";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {EMPTY} from "rxjs";
import {DeviceSlotSegment} from "@looma/shared/models/device_slot_segment";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RetailerPromoPeriodSubmission} from "@looma/shared/models/retailer_promo_period_submission";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {BrandProduct} from "@looma/shared/models/brand_product";
import {
    WineRecsCampaignGenerationComponent
} from "../wine-recs-campaign-generation/wine-recs-campaign-generation.component";

@LifecycleHooks()
@Component({
    selector: 'app-promo-schedule-program-overview',
    templateUrl: './promo-schedule-program-overview.component.html',
    styleUrls: ['./promo-schedule-program-overview.component.scss']
})
export class PromoScheduleProgramOverviewComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private svcApi: ApiDataService,
        private svcLayout: LayoutService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        @Optional() public dialogRef: MatDialogRef<PromoScheduleProgramOverviewComponent>
    ) {
        if (!this.initMainView(data)) {
            this.router.navigate(['/promo-schedules'])
        }

        if (this.dialogRef) {
            this.dialogRef.beforeClosed().pipe(takeUntil(Utils.onDestroy(this))).subscribe(() => {
                this.dialogRef.close(this.affectedBrandCampaigns);
            });
        }
    }

    promoPeriodEntry: PromoPeriodEntry;
    table = new Table();

    pageTitle = 'Schedule Overview';
    visibleSegments: DeviceSlotSegment[] = []

    cellSelection = new CellSelectionManager();
    promoProgram: RetailerPromoProgram;
    promoSchedule: RetailerPromoSchedule;

    brandCampaignsPanelVisible = true;
    editingSlotsForCampaign: BrandPromoCampaign;

    campaignSubmission: RetailerPromoPeriodSubmission

    affectedBrandCampaigns: BrandPromoCampaign[] = []

    brandCampaignsDataSource: ModelListDataSource<BrandPromoCampaign> = ModelListDataSource.create([{
        key: 'name',
        label: 'Name',
        valueReader: (item: BrandPromoCampaign): string => {
            return item.name
        }
    }], []);

    private campaignColors = new ColorSpace(0.4);

    static open(router: Router, sch: RetailerPromoSchedule, program: RetailerPromoProgram, promoPeriod: RetailerPromoPeriod) {
        let extras: NavigationExtras = {};
        if (promoPeriod) {
            extras = {
                queryParams: {
                    promo_period: promoPeriod.id
                }
            }
        }
        router.navigate([`promo-schedules/${sch.retailer.id}-${sch.id}-${program.id}/program-overview`], extras)
    }

    private initMainView(data: any): boolean {
        let scheduleId = null
        let programId = null
        let visiblePromoPeriodId = null

        if (data) {
            const submission: RetailerPromoPeriodSubmission = data.submission
            this.campaignSubmission = submission

            scheduleId = submission.schedule.retailerPromoPeriod.promoSchedule.id
            programId = submission.schedule.retailerPromoProgram.id
            visiblePromoPeriodId = submission.schedule.retailerPromoPeriod.id
        } else {
            const id = this.activatedRoute.snapshot.paramMap.get('id');
            if (!id) {
                return false
            }

            const chunks = id.split('-');
            if (chunks.length != 3) {
                return false
            }

            scheduleId = chunks[1];
            programId = chunks[2];
            visiblePromoPeriodId = this.activatedRoute.snapshot.queryParamMap.get('promo_period');
        }

        if (scheduleId && programId && visiblePromoPeriodId) {
            const vars = {
                scheduleId: scheduleId,
                programId: programId,
                promoPeriodIds: null
            }

            if (visiblePromoPeriodId) {
                vars.promoPeriodIds = [visiblePromoPeriodId]
            }

            this.svcApi.rawQueryNoCache({
                query: QUERY_FETCH_DATA,
                variables: vars
            }).subscribe(value => {
                const schedules = Utils.getNestedTypedArray(value.data, RetailerPromoSchedule, 'retailerPromoSchedules', 'data')
                const programs = Utils.getNestedTypedArray(value.data, RetailerPromoProgram, 'retailerPromoPrograms', 'data')
                if (schedules?.length == 1 && programs?.length == 1) {
                    this.setSchedule(schedules[0], programs[0], visiblePromoPeriodId)
                }
            })

            return true
        } else {
            return false
        }

    }

    ngOnInit() {
    }

    generateCampaigns() {
        WineRecsCampaignGenerationComponent.open(this.router, this.promoPeriodEntry.promoPeriod)
    }

    createNewBrandCampaign() {
        const cells = Array.from(this.cellSelection.selectedCells);

        if (!cells.length) {
            return;
        }
        const promoPeriods = new Map<string, RetailerPromoPeriod>();
        for (const cell of cells) {
            if (!cell.brandSlot.promoPeriod) {
                return;
            }
            promoPeriods.set(cell.brandSlot.promoPeriod.id, cell.brandSlot.promoPeriod)
        }
        if (promoPeriods.size != 1) {
            return 1
        }
        const promoPeriod = Array.from(promoPeriods.values())[0];

        const slotAssignments = Array.from(this.cellSelection.selectedCells).map(value => value.brandSlot.slotAssignment);
        if (!slotAssignments.length) {
            return;
        }
        const brandCampaign = new BrandPromoCampaign();
        brandCampaign.slotAssignments = slotAssignments;
        brandCampaign.promoPeriod = promoPeriod;
        brandCampaign.promoProgram = this.promoProgram;
        console.warn('brandCampaign', brandCampaign)
        this.editBrandCampaign(brandCampaign)

    }

    editBrandCampaign(brandCampaign: BrandPromoCampaign) {
        const toEdit = new BrandPromoCampaign();
        Object.assign(toEdit, brandCampaign.unwrap());
        toEdit.retailer = this.promoSchedule.retailer

        if (this.campaignSubmission) {
            const parentBrand = new BrandPartner()
            parentBrand.id = this.campaignSubmission.submittedByBrand.id
            parentBrand.name = this.campaignSubmission.submittedByBrand.name
            toEdit.brandPartner = parentBrand

            const featuredBrand = new BrandPartner()
            featuredBrand.id = this.campaignSubmission.brandPartner.id
            featuredBrand.name = this.campaignSubmission.brandPartner.name
            toEdit.featuredBrands = [featuredBrand]

            toEdit.name = featuredBrand.name

            const featuredProducts = this.campaignSubmission.featuredProducts.map(product => {
                const bp = new BrandProduct()
                bp.id = String(product.id)
                bp.name = product.name
                bp.upc_code = product.upc
                return bp
            })

            toEdit.campaignCostDollars = this.campaignSubmission.campaignCost
            toEdit.featuredProducts = featuredProducts
        }

        const newCampaign = toEdit.isNewRecord();

        BrandCampaignEditDialogComponent.open(this.svcLayout, toEdit, BRAND_CAMPAIGN_GQL_FIELDS, this.campaignSubmission).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value) {
                this.cellSelection.clear();
                this.handleBrandCampaignChanged(value, newCampaign ? BrandCampaignOp.CREATE : BrandCampaignOp.EDIT)
            }
        })
    }

    private setSchedule(sch: RetailerPromoSchedule, promoProgram: RetailerPromoProgram, visiblePromoPeriodId: string) {

        const entry = new PromoPeriodEntry(sch.promoPeriods[0]);
        this.promoPeriodEntry = entry

        this.pageTitle = `${sch.name} - ${promoProgram.name}`;
        this.promoProgram = promoProgram;
        this.promoSchedule = sch;

        this.visibleSegments = [].concat(entry.promoPeriod.deviceSlotSegments)

        this.refreshData();
    }


    private refreshData() {
        this.cellSelection.clear();
        this.table.clear();
        const topHeaderRow: TableCellConfig[] = [];

        for (const segment of this.visibleSegments) {
            topHeaderRow.push({
                rowSpan: 1,
                colSpan: 1,
                text: segment.name,
            });
        }


        this.table.headerRows.push({
            isHeader: true,
            cells: topHeaderRow.map(value => new TableCell(value))
        });


        const cycleColors = ['#f0f0f0', '#f9f9f9'];
        const unavailableBackgroundColor = `rgb(53, 58, 72)`;

        const periodEntry = this.promoPeriodEntry
        const cellColor = cycleColors.shift();

        for (let slotIndex = 0; slotIndex < periodEntry.maxTotalEditableSlots; slotIndex += 1) {
            const rowCells: TableCell[] = [];

            for (const segment of this.visibleSegments) {
                let tableCell: TableCell = null
                const max = periodEntry.getMaxSlotIndex(segment)

                if ((slotIndex == max) && periodEntry.promoPeriod.isEditable) {
                    tableCell = new TableCell({
                        rowSpan: 1,
                        colSpan: 1,
                        text: '',
                        sticky: false,
                        isActionCell: true,
                        isAssignableBrandSlotCell: false,
                    });
                    const key = getKey(periodEntry.promoPeriod, segment, slotIndex)
                    const slot = new BrandCampaignSlot(key, periodEntry.promoPeriod);
                    const assignment = new BrandPromoCampaignSlotAssignment();
                    assignment.slotIndex = slotIndex;
                    assignment.deviceSlotSegment = segment
                    assignment.brandCampaign = null;
                    slot.slotAssignment = assignment;
                    tableCell.brandSlot = slot;
                } else if (slotIndex < max) {
                    tableCell = periodEntry.cellRegistry.getItem(periodEntry.promoPeriod, segment, slotIndex);
                    const slotEntry = periodEntry.getBrandCampaignSlot(segment, slotIndex);
                    if (slotEntry && tableCell) {
                        tableCell.brandSlot = slotEntry
                        tableCell.config.color = cellColor;
                    }
                }

                tableCell = tableCell || new TableCell({
                    rowSpan: 1,
                    colSpan: 1,
                    text: '',
                    sticky: false,
                    isActionCell: false,
                    isAssignableBrandSlotCell: false,
                });

                if (tableCell) {
                    tableCell.selectionManager = this.cellSelection;
                    tableCell.config.color = cellColor;
                    rowCells.push(tableCell);
                }

            }
            this.table.bodyRows.push({
                isHeader: false,
                cells: rowCells
            })

        }


        for (const bc of this.promoPeriodEntry.promoPeriod.brandCampaigns) {
            this.promoPeriodEntry.applyBrandCampaignSlotAssignment(bc)
            this.promoPeriodEntry.cellRegistry.setItemsForBrandCampaign(bc, true);
        }

        // brandCampaigns data source
        this.brandCampaignsDataSource.setLocalData(this.promoPeriodEntry.promoPeriod.brandCampaigns);
    }

    deleteBrandCampaign(brandPromoCampaign: BrandPromoCampaign) {
        this.svcLayout.confirm('Delete Brand Campaign', `Are you sure you want to delete ${brandPromoCampaign.name}? Some of the existing playlists may need to be updated.`).pipe(
            switchMap(value => {
                if (!value) {
                    return EMPTY;
                }
                return this.svcApi.mutateBrandPromoCampaign(MutationOperation.Delete, {
                    id: brandPromoCampaign.getStringId(),
                }, BRAND_CAMPAIGN_GQL_FIELDS).pipe(
                    takeUntil(Utils.onDestroy(this))
                )
            })
        ).subscribe(value => {
            if (value.success) {
                this.brandCampaignsDataSource.removeItem(value.data);
                this.cellSelection.clear();
                this.handleBrandCampaignChanged(value.data, BrandCampaignOp.DELETE);
            } else {
                this.svcLayout.showMutationResultMessage(value);
            }
        });


    }

    private handleBrandCampaignChanged(bc: BrandPromoCampaign, op: BrandCampaignOp, refreshData = false) {
        if (!bc) {
            return null;
        }

        this.affectedBrandCampaigns.push(bc)

        this.promoPeriodEntry.applyBrandCampaignSlotAssignment(bc)
        this.promoPeriodEntry.cellRegistry.setItemsForBrandCampaign(bc, true);


        switch (op) {
            case BrandCampaignOp.CREATE:
                this.brandCampaignsDataSource.addItem(bc);
                break
            case BrandCampaignOp.EDIT:
                this.brandCampaignsDataSource.replaceItem(bc);
                break
            case BrandCampaignOp.DELETE:
                this.brandCampaignsDataSource.removeItem(bc);
                break
        }

        LOOP:
            for (const pp of this.promoSchedule.promoPeriods) {
                if (pp.id == bc?.promoPeriod?.id) {
                    switch (op) {
                        case BrandCampaignOp.EDIT:
                        case BrandCampaignOp.DELETE:
                            const idx = pp.brandCampaigns.findIndex(value => value.id == bc.id);
                            if (idx >= 0) {
                                if (op == BrandCampaignOp.DELETE) {
                                    pp.brandCampaigns.splice(idx, 1)
                                } else {
                                    pp.brandCampaigns[idx] = bc
                                }
                            }
                            break
                        case BrandCampaignOp.CREATE:
                            pp.brandCampaigns.push(bc)
                            break
                    }

                    break LOOP;
                }


            }

        if (refreshData) {
            this.refreshData();
        }
    }

    editBrandCampaignSlots(brandCampaign: BrandPromoCampaign) {
        brandCampaign = brandCampaign.unwrap();
        this.editingSlotsForCampaign = brandCampaign;
        this.getCellRegistryForBrandCampaign(brandCampaign)?.selectItemsForBrandCampaign(brandCampaign, this.cellSelection);
        this.cellSelection.setBrandCampaign(brandCampaign)
    }

    canEditBrandCampaignSlots(brandCampaign: BrandPromoCampaign) {
        return this.promoPeriodEntry?.isEditable
    }

    applySlotSelectionToCampaign(brandCampaign: BrandPromoCampaign) {
        const selection = Array.from(this.cellSelection.selectedCells);
        this.editingSlotsForCampaign = null;
        this.cellSelection.clear();
        this.cellSelection.setBrandCampaign(null);
        this.editingSlotsForCampaign = null;
        if (!brandCampaign) {
            return
        }

        for (const x of brandCampaign.slotAssignments) {
            x.brandCampaign = null;
        }

        brandCampaign.slotAssignments.length = 0;

        const mutationSlotAssignments: BrandPromoCampaignSlotAssignmentInput[] = [];
        for (const sel of selection) {
            sel.brandSlot.slotAssignment.brandCampaign = brandCampaign;
            brandCampaign.slotAssignments.push(sel.brandSlot.slotAssignment);

            mutationSlotAssignments.push({
                deviceSlotSegmentId: sel.brandSlot.slotAssignment.deviceSlotSegment.getStringId(),
                slotIndex: sel.brandSlot.slotAssignment.slotIndex
            })
        }


        this.svcApi.mutateBrandPromoCampaign(MutationOperation.Update, {
            id: brandCampaign.getStringId(),
            slotAssignments: mutationSlotAssignments,

        }, BRAND_CAMPAIGN_GQL_FIELDS).pipe(
            takeUntil(Utils.onDestroy(this))
        ).subscribe(value => {
            if (value.success) {
                this.cellSelection.clear();
                this.getCellRegistryForBrandCampaign(value.data)?.setItemsForBrandCampaign(value.data, true)
            } else {
                this.svcLayout.showMutationResultMessage(value);
            }
        });

    }

    getCellRegistryForBrandCampaign(brandCampaign: BrandPromoCampaign): EditableCellRegistry {
        if (!brandCampaign) {
            return null;
        }
        return this.promoPeriodEntry.cellRegistry
    }

    getBackgroundColor(cell: TableCell | BrandPromoCampaign): string {
        if (Utils.isFunction(cell['unwrap'])) {
            cell = cell['unwrap']();
        }
        let brandCampaign = null;
        if (cell instanceof TableCell) {
            brandCampaign = cell.brandSlot.slotAssignment && cell.brandSlot.slotAssignment.brandCampaign || null;
        } else if (cell instanceof BrandPromoCampaign) {
            brandCampaign = cell
        }

        if (!brandCampaign) {
            return 'transparent';
        }
        return this.campaignColors.getColor(brandCampaign.id);

    }

    insertCell(cell: TableCell) {
        const segment = cell?.brandSlot?.slotAssignment?.deviceSlotSegment
        if (!segment) {
            return
        }
        this.promoPeriodEntry.incMaxEditableSlots(segment);
        this.refreshData()
    }

    canGenerateCampaigns() {
        return !this.promoPeriodEntry?.promoPeriod?.isEnded && this.promoProgram?.name == "In-Aisle Wine Recs"
    }
}

class PromoPeriodEntry {
    constructor(public promoPeriod: RetailerPromoPeriod) {
        this.init()
    }

    maxTotalEditableSlots: number;

    slotAssignmentsMap = new Map<string, BrandCampaignSlot>();

    cellRegistry = new EditableCellRegistry();

    maxEditableSlots = new Map<string, number>()

    private setMaxSlotIndex(segment: DeviceSlotSegment, value: number) {
        this.maxEditableSlots.set(segment.id, value)
    }

    getMaxSlotIndex(segment: DeviceSlotSegment): number {
        if (this.maxEditableSlots.has(segment.id)) {
            return this.maxEditableSlots.get(segment.id)
        }
        return this.maxTotalEditableSlots
    }

    incMaxEditableSlots(segment: DeviceSlotSegment) {
        let newValue = this.getMaxSlotIndex(segment) + 1
        this.setMaxSlotIndex(segment, newValue);

        if (this.promoPeriod.isEditable) {
            newValue += 1
        }
        this.maxTotalEditableSlots = Math.max(this.maxTotalEditableSlots, newValue)
    }

    init() {
        this.slotAssignmentsMap.clear();

        let maxTotalSlots = 0;
        for (const segment of this.promoPeriod.deviceSlotSegments) {
            maxTotalSlots = Math.max(maxTotalSlots, segment.defaultBrandSlotsCount || maxTotalSlots);
            this.setMaxSlotIndex(segment, segment.defaultBrandSlotsCount);
        }
        const defaultMaxTotal = maxTotalSlots;

        for (const brandCampaign of this.promoPeriod.brandCampaigns) {
            const assignedSegmentsMap = new Map<string, DeviceSlotSegment>()
            for (const assignation of brandCampaign.slotAssignments) {
                assignedSegmentsMap.set(assignation.deviceSlotSegment.id, assignation.deviceSlotSegment)
            }

            for (const segment of assignedSegmentsMap.values()) {
                const maxSlotIndexForTypeAndRegion = Math.max(
                    this.getMaxSlotIndexForBrandCampaign(brandCampaign, segment) || 0,
                    this.getMaxSlotIndex(segment) || 0,
                    defaultMaxTotal
                );
                maxTotalSlots = Math.max(maxTotalSlots, maxSlotIndexForTypeAndRegion)

                this.setMaxSlotIndex(segment, maxSlotIndexForTypeAndRegion);
            }

        }

        if (this.promoPeriod.isEditable) {
            // making room for +Empty Slot row
            maxTotalSlots += 1;
        }

        this.maxTotalEditableSlots = maxTotalSlots;
    }

    getMaxSlotIndexForBrandCampaign(camp: BrandPromoCampaign, segment: DeviceSlotSegment): number {
        let idx = 0
        if (!Array.isArray(camp.slotAssignments)) {
            return 0;
        }
        for (const x of camp.slotAssignments) {
            if (x.deviceSlotSegment?.id == segment.id) {
                idx = Math.max(idx, x.slotIndex)
            }
        }
        return idx + 1
    }

    applyBrandCampaignSlotAssignment(brandCampaign: BrandPromoCampaign) {
        for (const slotAssignment of brandCampaign.slotAssignments) {
            const key = getKey(this.promoPeriod, slotAssignment.deviceSlotSegment, slotAssignment.slotIndex);
            const slot = this.slotAssignmentsMap.get(key) || new BrandCampaignSlot(key, this.promoPeriod);
            slot.isDirty = false;
            slot.slotAssignment = slotAssignment;
            slot.slotAssignment.brandCampaign = brandCampaign

            this.slotAssignmentsMap.set(key, slot);
        }
    }

    getBrandCampaignSlot(segment: DeviceSlotSegment, slotIndex: number): BrandCampaignSlot | null {
        const key = getKey(this.promoPeriod, segment, slotIndex);
        if (this.slotAssignmentsMap.has(key)) {
            return this.slotAssignmentsMap.get(key)
        }

        const slot = new BrandCampaignSlot(key, this.promoPeriod);

        const slotAssignment = slot.slotAssignment = new BrandPromoCampaignSlotAssignment();

        slotAssignment.slotIndex = slotIndex;
        slotAssignment.deviceSlotSegment = segment
        this.slotAssignmentsMap.set(key, slot)
        return slot

    }

    get isEditable(): boolean {
        return this.promoPeriod?.isEditable || false
    }
}

class BrandCampaignSlot {
    constructor(public key: string, public promoPeriod: RetailerPromoPeriod) {
    }

    slotAssignment: BrandPromoCampaignSlotAssignment;

    isDirty = false;

    get hasAssignedBrandCampaign(): boolean {
        return !!(this.slotAssignment && this.slotAssignment.brandCampaign)
    }

    get displayText(): string {
        if (!this.slotAssignment) {
            return ''
        }
        if (this.slotAssignment.brandCampaign) {
            return this.slotAssignment.brandCampaign.name;
        }

        return 'Empty Slot'
    }

    get canEditSlots(): boolean {
        return this.promoPeriod && !this.promoPeriod.isEditable
    }
}


interface TableCellConfig {
    colSpan: number;
    rowSpan: number;
    text: string;
    isAssignableBrandSlotCell?: boolean
    isDisabled?: boolean
    isActionCell?: boolean
    sticky?: boolean,
    color?: string
}

class TableCell {
    brandSlot: BrandCampaignSlot;

    private _isSelected = false;
    selectionManager: CellSelectionManager;

    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        if (this._isSelected != value) {
            this._isSelected = value;
            if (this.selectionManager) {
                this.selectionManager.setItemSelected(this, value);
            }
        }
    }

    constructor(public config: TableCellConfig) {
    }

    get hasDirtyContent(): boolean {
        return this.brandSlot && this.brandSlot.isDirty;
    }

    get isBodyCell(): boolean {
        return !!this.brandSlot
    }

    get isActionCell(): boolean {
        return this.config.isActionCell === true
    }

    get isBrandAssignableCell(): boolean {
        return this.config.isAssignableBrandSlotCell === true
    }

    get isDisabled(): boolean {
        return this.config.isDisabled === true
    }

    get isEmptySlot(): boolean {
        return !this.brandSlot.hasAssignedBrandCampaign
    }


}


interface TableRow {
    isHeader: boolean;
    cells: TableCell[]
}


class Table {
    headerRows: TableRow[] = [];
    bodyRows: TableRow[] = [];


    clear() {
        this.headerRows.length = this.bodyRows.length = 0;
    }
}

class EditableCellRegistry {
    private cellRegistry = new Map<string, TableCell>();

    getItem(promoPeriod: RetailerPromoPeriod, segment: DeviceSlotSegment, slotIndex: number) {
        const key = getKey(promoPeriod, segment, slotIndex)
        const item = this.cellRegistry.get(key);
        if (item) {
            return item
        }
        const slot = new BrandCampaignSlot(key, promoPeriod);
        const assignment = new BrandPromoCampaignSlotAssignment();
        assignment.deviceSlotSegment = segment;
        assignment.slotIndex = slotIndex;
        slot.slotAssignment = assignment;

        const cfg: TableCellConfig = {
            isAssignableBrandSlotCell: true,
            sticky: false,
            colSpan: 1,
            rowSpan: 1,
            text: '',
            isDisabled: !promoPeriod.isEditable
        }
        const cell: TableCell = new TableCell(cfg)
        cell.brandSlot = slot;
        this.cellRegistry.set(key, cell)
        return cell
    }

    selectItemsForBrandCampaign(brandCampaign: BrandPromoCampaign, selectionManager: CellSelectionManager) {
        selectionManager.clear();

        for (const cell of this.cellRegistry.values()) {
            if (cell.brandSlot.hasAssignedBrandCampaign) {
                if (cell.brandSlot.slotAssignment?.brandCampaign?.id == brandCampaign.id) {
                    cell.isSelected = true;
                }
            }
        }
    }

    setItemsForBrandCampaign(brandCampaign: BrandPromoCampaign, clearPrevious = false) {
        if (clearPrevious) {
            for (const v of this.cellRegistry.values()) {
                if (v.brandSlot?.slotAssignment?.brandCampaign?.id == brandCampaign.id) {
                    v.brandSlot.slotAssignment.brandCampaign = null
                }
            }
        }

        const brandAssignments = new Map<string, BrandPromoCampaignSlotAssignment>()
        for (const cellAssignment of brandCampaign.slotAssignments) {
            const k = getKey(brandCampaign.promoPeriod, cellAssignment.deviceSlotSegment, cellAssignment.slotIndex)
            const cell = this.cellRegistry.get(k);
            if (cell) {
                cell.brandSlot.slotAssignment = cellAssignment;
                cellAssignment.brandCampaign = brandCampaign
            }
            brandAssignments.set(k, cellAssignment)
        }

    }
}

function getKey(promoPeriod: RetailerPromoPeriod, segment: DeviceSlotSegment, slotIndex: number): string {
    return `${promoPeriod.id}-${segment.id}-${slotIndex}`
}

class CellSelectionManager {
    selectedCells = new Set<TableCell>();
    private brandCampaign: BrandPromoCampaign;

    setItemSelected(cell: TableCell, newIsSelected: boolean) {
        if (newIsSelected) {
            this.selectedCells.add(cell)
        } else {
            this.selectedCells.delete(cell)
        }
    }

    setBrandCampaign(brandCampaign: BrandPromoCampaign) {
        this.brandCampaign = brandCampaign;
    }

    isItemSelectable(cell: TableCell): boolean {
        if (!this.brandCampaign) {
            return true
        }
        if (cell.brandSlot.promoPeriod.id != this.brandCampaign.promoPeriod.id) {
            return false;
        }
        const assignment = cell.brandSlot.slotAssignment;
        if (!assignment || !assignment.brandCampaign) {
            return true;
        }
        if (assignment.brandCampaign.id == this.brandCampaign.id) {
            return true
        }
        return false;
    }

    get hasSelectedItems(): boolean {
        return this.selectedCells.size > 0
    }

    clear() {
        for (const sel of Array.from(this.selectedCells)) {
            sel.isSelected = false;
        }
    }

    get canAssignBrandCampaign(): boolean {
        if (!this.hasSelectedItems) {
            return false
        }
        const periods = new Set();
        for (const sel of this.selectedCells) {
            if (sel.brandSlot.hasAssignedBrandCampaign) {
                return false
            }
            periods.add(sel.brandSlot.promoPeriod.id);
        }
        return periods.size == 1;
    }

    get emptySlotsSelected(): boolean {
        if (!this.hasSelectedItems) {
            return false
        }
        for (const sel of this.selectedCells) {
            if (sel.brandSlot.hasAssignedBrandCampaign) {
                return false;
            }
        }
        return true
    }
}

export const BRAND_CAMPAIGN_GQL_FIELDS = `
id
name
isVisible
isPilot
cprEnabled
executionReportingEnabled
campaignCostDollars
campaignDiscountDollars
retailer {
    id
    retailer_name
}
promoPeriod {
  id
  name
}
promoProgram {
  id
  name
}
brandPartner {
  id
  name
}
featuredProducts {
  id
  name
}
featuredBrands {
  id
  name
}
slotAssignments {
  id
  slotIndex
  deviceSlotType {
    id
    name
  }
  retailerRegion {
    id
    region_name
  }
  deviceSlotSegment {
    id
    name
  }
}
contactUsers {
  id
  display_name
  email
}
attachments{
  attachmentType
  downloadUrl
  fileName
}
cprRecipientsUsers {
  id
  display_name
  email
}
`

const QUERY_FETCH_DATA = gql`
    query fetchData($scheduleId: ID!, $programId: ID!, $promoPeriodIds: [ID!]) {
        retailerPromoPrograms(filter: { ids: [$programId] }) {
            data: retailerPromoPrograms {
                id
                name
            }
        }
        retailerPromoSchedules(filter: { id: $scheduleId }) {
            data: retailerPromoSchedules {
                name
                retailer {
                    id
                    retailer_name
                }
                promoPeriods(ids: $promoPeriodIds) {
                    id
                    status
                    name
                    startDate
                    endDate
                    retailer {
                        id
                        retailer_name
                    }
                    deviceSlotSegments(filter:{promoProgramId: $programId}) {
                        id
                        name
                        defaultBrandSlotsCount
                    }
                    brandCampaigns(promoProgramIds: [$programId]) {
                        ${BRAND_CAMPAIGN_GQL_FIELDS}
                    }
                }
            }
        }
    }

`

enum BrandCampaignOp {
    EDIT = 1,
    CREATE = 2,
    DELETE = 3,
}
