import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MediaContentTabControllerService} from "../media-contents-list/media-contents-list.component";
import {LifecycleHooks} from "@looma/shared/lifecycle_utils";
import {UploadedFileInfo, UploadService, UploadSession} from "@looma/shared/services/upload.service";
import {GetJobTokenFunc, ToastNotificationService} from "../../../services/toast-notification.service";
import {debounceTime, flatMap, map, takeUntil} from "rxjs/operators";
import {Utils} from "@looma/shared/utils";
import {EMPTY, merge, Observable, of, Subject} from "rxjs";
import {RunningJobInfo} from "@looma/shared/services/remote-job.service";
import gql from "graphql-tag";
import {FRAGMENT_API_RESPONSE_FIELDS} from "../../../services/queries";
import {ApiDataService} from "../../../services/api-data.service";
import {BaseCursorLoader} from "@looma/shared/cursor_loader";
import {CursorFeed} from "@looma/shared/cursor_feed";
import {MediaContent, MediaContentFilterCriteria, MediaContentType} from "@looma/shared/models/media_content";
import {DomSanitizer} from "@angular/platform-browser";
import {MediaContentVersion, MediaContentVersionType} from "@looma/shared/models/media_content_version";
import {VideoPlayerService} from "../../../services/video-player.service";
import {
    MediaContentEditComponent,
    MUTATION_DELETE_MEDIA_CONTENT
} from "../media-content-edit/media-content-edit.component";
import {LayoutService} from "../../../services/layout.service";
import {MutationResponse} from "@looma/shared/types/mutation_response";
import {MutationOperation} from "@looma/shared/models/mutation_operation";
import {ApiResponse} from "@looma/shared/models/api_response";
import {Router} from "@angular/router";

interface UploadInfo {
    uploadType: string
    file: UploadedFileInfo
    job: RunningJobInfo
    mediaContentId: string
}

@LifecycleHooks()
@Component({
    selector: 'app-media-content-type-list',
    templateUrl: './media-content-type-list.component.html',
    styleUrls: ['./media-content-type-list.component.scss']
})
export class MediaContentTypeListComponent implements OnInit, OnDestroy {

    private readonly videoUploadSession: UploadSession =
        this.svcUpload.getUploadSession('media_content_video_session', {
            fileTypes: ['video'],
            multiSelection: false
        });

    private readonly splashUploadSession: UploadSession =
        this.svcUpload.getUploadSession('media_content_splash_session', {
            fileTypes: ['image', 'video'],
            multiSelection: false
        });


    contentTypeEnum: string;
    mediaContentLoader: MediaContentLoader;
    mediaContent: MediaContent

    loop = MediaContentVersionType.loop
    clean = MediaContentVersionType.clean
    versionTypeForUpload: MediaContentVersionType

    private filter = new MediaContentFilter();

    constructor(private svcVideoPlayer: VideoPlayerService,
                private svcTabs: MediaContentTabControllerService,
                private svcUpload: UploadService,
                private svcToastNotif: ToastNotificationService,
                private svcLayout: LayoutService,
                private router: Router,
                private svcApi: ApiDataService,
                private sanitization: DomSanitizer) {

        this.videoUploadSession.onFileUploaded().subscribe(v => console.log(v.firebaseKey))
    }

    @Input('contentType') set contentType(value: string) {
        this.contentTypeEnum = value
    }

    addNewMediaContent(file: UploadedFileInfo): Observable<ApiResponse> {
        return this.svcApi.rawMutate(MUTATION_ADD_MEDIA_CONTENT, {
            data: {
                gsFileLocation: file.firebaseKey,
                contentType: this.contentTypeEnum,
                fileName: file.fileName,
                filmTypeEnum: this.versionTypeForUpload
            }
        }, "uploadMediaContent")
    }

    addNewVersion(file: UploadedFileInfo): Observable<ApiResponse> {
        return this.svcApi.rawMutate(MUTATION_ADD_MEDIA_CONTENT, {
            data: {
                gsFileLocation: file.firebaseKey,
                contentType: this.contentTypeEnum,
                fileName: file.fileName,
                mediaContentId: this.mediaContent.id,
                filmTypeEnum: this.versionTypeForUpload
            }
        }, "uploadMediaContent")
    }

    ngOnInit(): void {
        this.svcTabs.setActiveTab(this);
        this.mediaContentLoader = new MediaContentLoader(this.contentTypeEnum, this.svcApi,
            this.filter.onChanged().pipe(
                takeUntil(Utils.onDestroy(this)) as any,
                debounceTime(100) as any,
            ))

        const x: () => GetJobTokenFunc = () => {
            return file => {
                let obs: Observable<ApiResponse>;
                if (this.isUploadNewContent()) {
                    obs = this.addNewMediaContent(file)
                } else {
                    obs = this.addNewVersion(file)
                }

                return obs.pipe(
                    takeUntil(Utils.onDestroy(this)),
                    flatMap(value => {
                        file.extra.set("mcId", value.data.mediaContentId)
                        return of(value.data.firebaseJobId)
                    })
                )
            }
        };

        merge(
            this.svcToastNotif.connect(this.splashUploadSession, x()).pipe(
                takeUntil(Utils.onDestroy(this)),
                map(value => {
                    const v: UploadInfo = {
                        uploadType: 'splash_image',
                        file: value.file,
                        job: value.job,
                        mediaContentId: value.file.extra.get("mcId")
                    };
                    return v
                })
            ),
            this.svcToastNotif.connect(this.videoUploadSession, x()).pipe(
                takeUntil(Utils.onDestroy(this)),
                map(value => {
                    const v: UploadInfo = {
                        uploadType: 'video_file',
                        file: value.file,
                        job: value.job,
                        mediaContentId: value.file.extra.get("mcId")
                    };
                    return v
                })
            )
        ).pipe(
            takeUntil(Utils.onDestroy(this)),
            flatMap((value: UploadInfo) => {
                if (value.job.isSuccess) {
                    return this.getMediaContent(value.mediaContentId)
                } else {
                    return EMPTY
                }
            })
        ).subscribe(value => {
            if (value) {
                if (this.isUploadNewContent()) {
                    this.mediaContentLoader.prependItem(value)
                } else {
                    this.mediaContentLoader.replaceItem(value)
                }
                this.mediaContent = null
                MediaContentEditComponent.open(this.svcLayout, value)
                    .pipe(takeUntil(Utils.onDestroy(this)))
                    .subscribe(result => {
                            if (result) {
                                if (result.contentType == this.contentTypeEnum) {
                                    this.mediaContentLoader.replaceItem(result);
                                } else {
                                    this.mediaContentLoader.removeItem(result);
                                }
                            }
                        }
                    )
            }
        });
    }

    ngOnDestroy(): void {
        this.splashUploadSession?.destroy();
        this.videoUploadSession?.destroy();
    }

    openUploadPicker(mc: MediaContent | null, versionType: MediaContentVersionType) {
        this.mediaContent = mc
        this.versionTypeForUpload = versionType
        if (this.contentTypeEnum == MediaContentType.Film ||
            this.contentTypeEnum == MediaContentType.AncillaryContent) {
            this.videoUploadSession.openPicker()
        } else {
            this.splashUploadSession.openPicker()
        }
    }

    getBackgroundThumbImage(data: MediaContent) {
        let thumbURL;
        if (data.contentType == MediaContentType.Film) {
            thumbURL = data.externalThumbUrl ? data.externalThumbUrl : data.defaultMediaContentVersion?.thumbnail
        } else {
            thumbURL = data.defaultMediaContentVersion?.thumbnail
        }
        if (thumbURL) {
            return this.sanitization.bypassSecurityTrustStyle(` url(${thumbURL})`);
        }
    }

    playItem(m: MediaContentVersion, player: 'player' | 'external'): void {
        const url = m.processedUrl;
        switch (player) {
            case "player":
                this.svcVideoPlayer.play(url, m.id);
                break;
            case "external":
                window.open(url, '_blank');
                break
        }
    }

    edit(data: MediaContent) {
        MediaContentEditComponent.open(this.svcLayout, data)
            .pipe(takeUntil(Utils.onDestroy(this)))
            .subscribe(result => {
                    if (result) {
                        if (result.contentType == this.contentTypeEnum) {
                            this.mediaContentLoader.replaceItem(result);
                        } else {
                            this.mediaContentLoader.removeItem(result);
                        }
                    }
                }
            )
    }

    delete(mc: MediaContent) {
        if (!mc) {
            return
        }
        this.svcLayout.confirm(`Delete content`, `Are you sure you want to delete ${mc.displayName}?`).pipe(
            flatMap(confirm => {
                if (confirm) {
                    return this.svcApi.rawObjectMutate(MUTATION_DELETE_MEDIA_CONTENT, {
                        op: MutationOperation.Delete,
                        data: {
                            id: mc.id
                        },
                    }, MediaContent)
                } else {
                    return EMPTY;
                }
            }),
            takeUntil(Utils.onDestroy(this))
        ).subscribe((resp: MutationResponse<MediaContent>) => {
            this.svcLayout.showMutationResultMessage(resp)
            if(resp.success){
                this.mediaContentLoader.removeItem(mc);
            }
        });
    }

    download(mc: MediaContent, version: string) {
        if (!mc) {
            return
        }
        let url = ''
        switch (version) {
            case 'original':
                url = mc.defaultMediaContentVersion.originalUrl || url
                break
            case 'processed':
                url = mc.defaultMediaContentVersion.processedUrl || url
                break
            default:
                break
        }
        if (url != '') {
            window.open(url, "_blank");
        }
    }

    searchByFilmNameOrFilmMaker(term: string) {
        this.filter.setValue("textSearchPattern", term)
    }

    searchByBrandPartner(bpId: string) {
        this.filter.setValue("brandPartnerId", bpId)
    }

    uploadNewVersion(data: MediaContent, versionType: MediaContentVersionType) {
        if (!data) {
            return;
        }
        this.openUploadPicker(data, versionType)
    }


    private getMediaContent(id: string): Observable<MediaContent> {
        return this.svcApi.rawQuery({
            query: MEDIA_CONTENT_LIST_QUERY,
            fetchPolicy: 'no-cache',
            variables: {
                criteria: {
                    id: id
                }
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data']['mediaContents'] as any;
                return (new MediaContent().assign(rawData[0]))
            })
        );
    }

    private isUploadNewContent(): boolean {
        return this.mediaContent == null;
    }

    showFilmVariables(data: MediaContent) {
        this.router.navigateByUrl(`/media-content/film-variables/${data.id}`).then(() => {
        });
    }
}


class MediaContentLoader extends BaseCursorLoader<MediaContent> {

    private filters: MediaContentFilterCriteria;

    constructor(private contentType: string, private svcApi: ApiDataService, obs: Observable<MediaContentFilter>) {
        super();
        obs.subscribe(value => {
            this.filters = value.data;
            this.invalidate()
        })
    }

    next(startFrom: string): Observable<CursorFeed<MediaContent>> {
        const filters: any = {
            contentType: this.contentType
        };
        Object.assign(filters, this.filters);
        if (startFrom) {
            Object.assign(filters, {cursor: startFrom});
        }

        return this.svcApi.rawQuery({
            query: MEDIA_CONTENT_LIST_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                criteria: filters
            }
        }).pipe(
            map(value => {
                const rawData = value.data['data'] as any;
                return CursorFeed.create(rawData, MediaContent, 'mediaContents')
            })
        );
    }
}

class MediaContentFilter {
    data: Partial<MediaContentFilterCriteria> = {};
    changes = new Subject<MediaContentFilter>();

    setValue(fieldName: string, value: string): void {
        if (value) {
            this.data[fieldName] = value
        } else {
            delete this.data[fieldName]
        }
        this.changes.next(this)
    }

    onChanged(): Observable<MediaContentFilter> {
        return this.changes
    }
}


export const MUTATION_ADD_MEDIA_CONTENT = gql`
    ${FRAGMENT_API_RESPONSE_FIELDS}
    mutation uploadMediaContent(
        $data: UploadMediaContentInput!
    ) {
        uploadMediaContent(
            data: $data
        ) {
            ...ApiResponseFields
        }
    }
`;

export const FRAGMENT_MEDIA_CONTENT_FIELDS = gql`
    fragment MediaContentFields on MediaContent {
        id
        contentType
        displayName
        description
        filmMaker
        vimeoVideoId
        isDraft
        isVisible
        storyType
        slideType
        storyIqScore
        featuredProduct
        filmSplashImageUrl
        featuredBrandCampaigns {
            id
            name
            retailer {
                id
                retailer_id
            }
        }
        brandPartner {
            id
            name
            type
            childBrands{
                id
                name
                type
            }
            parentBrand{
                id
                name
                type
            }
        }
        retailer {
            id
            retailer_name
        }
        externalUrl
        externalThumbUrl
        externalDownloadUrl
        defaultMediaContentVersion {
            id
            fileName
            originalUrl
            processedUrl
            thumbnail
            createdAt
        }
        cleanMediaContentVersion {
            id
            fileName
            processedUrl
            thumbnail
            createdAt
        }
        mediaContentVersions {
            id
            fileName
            processedUrl
            thumbnail
            createdAt
        }
        cleanVersions {
            id
            fileName
            processedUrl
            thumbnail
            createdAt
        }
    }
`

const MEDIA_CONTENT_LIST_QUERY = gql`
    ${FRAGMENT_MEDIA_CONTENT_FIELDS}
    query media_content($criteria: MediaContentFilterCriteria!){
        data: mediaContents(criteria:$criteria){
            cursor
            mediaContents {
                ...MediaContentFields
            }
        }
    }
`;
